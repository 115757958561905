<script>
import {relatorioLancesPorLeilao} from "@/domain/leiloes/services"
import RelatorioTemplate from "./RelatorioTemplate"
import {relatorio} from "@/domain/relatorios/services"

export default {
  mixins: [RelatorioTemplate],
  nome: 'LeilaoRelatoriosLances',
  inject: {
    printView: {
      default () {
        console.error('Main needs to be child of PrintView')
      }
    }
  },
  created() {
    this.isLoading = true
    relatorioLancesPorLeilao(this.leilao.id)
        .then((response) => {
          this.dados = response.data
          this.isLoading = false
          this.printView.printOptions.excel =  () => {
            relatorio('lances', {id: this.leilao.id}, 'excel', true)
          }
        })
        .catch((response) => {
          // this.isLoading = false
          this.alertApiError(response)
          console.log(response)
        })
  }
}
</script>
